.header {
  text-align: center;
  font-weight: 700;
  font-size: 24px;
  color: #4b97d5;
  margin-bottom: 25px;
}

.header.start {
  text-align: start;
}
