.projects-container {
  background: url("/public/assets/images/portfolio/bg.png");
}

.projects-content {
  display: flex;
  overflow-x: auto;
  border-radius: 15px;
  filter: drop-shadow(6px 6px 12px rgba(0, 0, 0, 0.25));
  padding: 0;
  background: #3f9cd5;
}

.projects-content::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
.projects-content::-webkit-scrollbar-thumb {
  background: #ffffff;

  border-radius: 20px;
}

.projects-list {
  padding: 60px 0;
}

.projects-list > h2 {
  text-align: center;
  font-weight: 600;
}

.projects-list > div {
  text-align: center;
  font-size: 18px;
}

.projects-image {
  margin: 16px;
  filter: drop-shadow(6px 6px 6px rgba(0, 0, 0, 0.25));
}

.carousel-projects {
  display: flex;
  justify-content: center;
}

.carousel-projects.mobile {
  padding: 8px;
}

.projects-image.image {
  margin: 0;
}
