.visi-misi-komitmen {
  background: url("/public/assets/images/visi-misi/bg.png");
  background-size: cover;
  min-height: 1053px;
  display: flex;
  align-items: center;
  padding: 100px 0;
}

.visimisi-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 800px;
}

.visimisi-wrapper-2 {
  display: flex;
  justify-content: space-around;
  align-items: center;

  margin-top: 0;

  height: 480px;
}

.first-row.left {
}

.first-row,
.second-row {
  width: 98%;
  height: 350px;

  margin-top: 15px;
  margin-bottom: 15px;

  margin-left: auto;
  margin-right: auto;
  padding: 40px;

  border-radius: 20px;
  background-color: #3f9cd5;

  color: #fff;
}

.second-row {
  height: 100%;
}

.first-row > h2,
.second-row > h2 {
  text-align: center;
}

.first-row > hr,
.second-row > hr {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  width: 10%;
}

.first-row,
.second-row,
.first-row > h2,
.second-row > h2 {
  transition-duration: 0.5s;
}

.first-row:hover,
.second-row:hover {
  transform: scale(1.02);
}

@media only screen and (max-width: 1200px) {
  .first-row {
    height: 600px;
  }

  .first-row:hover {
    transform: scale(1.02);
  }
}

@media only screen and (max-width: 768px) {
  .first-row,
  .second-row {
    height: 100%;
    padding: 20px;
  }

  .first-row:hover {
    transform: scale(1.02);
  }
}
