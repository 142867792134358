.carousel-control-prev {
  opacity: 1 !important;
}

.carousel-control-next {
  opacity: 1 !important;
}

.carousel-control-prev-icon {
  background-image: url("/public/assets/icons/carousel/prev.svg") !important;
}
.carousel-control-next-icon {
  background-image: url("/public/assets/icons/carousel/next.svg") !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 2rem !important;
  height: 2rem !important;
  transform: scale(2);
  filter: drop-shadow(3px 3px 4px rgba(0, 0, 0, 0.25));
  transition: 0.3s;
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  transform: scale(2.05);
}

.carousel-indicators {
  visibility: hidden;
}

@media (max-width: 576px) {
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    transform: scale(1.5);
  }

  .carousel-control-prev-icon:hover,
  .carousel-control-next-icon:hover {
    transform: scale(1.6);
  }
}
