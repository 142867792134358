.fixed-bottom {
  visibility: hidden;
  display: flex;
  flex-direction: column;
  margin: 30px;
  margin-left: auto;
  width: 60px;
  height: 60px;
  opacity: 0;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
  transition: 0.3s;
}

.fixed-bottom.show {
  visibility: visible;
  opacity: 1;
}

.up-button {
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
}

@media (max-width: 576px) {
  .fixed-bottom {
    margin: 40px;
    margin-right: 10px;
    margin-left: auto;
    transform: scale(0.8);
  }
}
