.teknologi {
  padding: 100px 0;
}

.container {
  padding: 0 60px;
  width: 100%;
  overflow: auto;
}

.table {
  width: 100%;
}

th {
  background-color: #4b97d5;
  color: #fff;
}

/* rounded corner */
th:first-child {
  border-radius: 20px 0 0 0;
}
th:last-child {
  border-radius: 0 20px 0 0;
}

tr:last-child > td:last-child {
  border-radius: 0 0 20px 0;
}

tr:last-child > td:first-child {
  border-radius: 0 0 0 20px;
}

.table * td:hover {
  background-color: rgb(196, 196, 196);
}

td {
  cursor: pointer;
  background-color: rgb(236, 236, 236);
  color: rgb(51, 51, 51);
  transition: 0.3s;
}

th,
td {
  border: solid 3px #fff !important;
  padding: 20px;
}

@media (max-width: 576px) {
  .container {
    padding: 0;
  }
}
