.navbar {
  transition-timing-function: ease-in;
  transition: 0.3s;
  padding: 0 60px !important;
  font-size: 14px;
  letter-spacing: 0.1em;
}

.navbar * div {
  color: rgb(255, 255, 255);
}

.nav-color {
  background-color: #fff;
  font-size: 14px;
}

.nav-color *.nav-link:after {
  background-color: rgb(50, 50, 50);
}
.nav-color * div {
  color: rgb(50, 50, 50);
}

.nav-link {
  margin-left: 3px;
}

.nav-contact {
  border: solid 1px;
  border-radius: 20px;
  text-align: center;
  width: 100px;
}

.nav-link {
  display: inline-block;
  position: relative;
  color: #0087ca;
}

.nav-link:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
}

.nav-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.nav-contact {
}

@media (max-width: 991px) {
  .navbar {
    padding: 0 20px !important;
    background-color: #fff;
  }
  .navbar * div {
    color: rgb(50, 50, 50);
  }
}
