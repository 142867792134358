.parent {
  background: url("/public/assets/images/ourteam/ourteam-all.png");
  background-size: cover;
  background-color: #fff;
  padding: 0 60px;
}

.title {
  text-align: center;
  width: 100%;
  padding: 20px 0;
  margin-bottom: -80px;
  color: #fff;
  font-weight: 600px;
}

.row {
}

.column {
  display: inline-block;
  margin: 20px;
  width: 190px;
  height: 228px;
}

.column > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.column > span {
  position: absolute;
  visibility: hidden;
  background-color: black;
  opacity: 0;
  width: 190px;
  height: 228px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.column > span > div:first-child {
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;

  letter-spacing: 0.02em;
  text-align: center;
  color: #3f9cd5;
}

.column > span > div:last-child {
  color: #fff;
  text-align: center;
  font-size: 12px;
}

.column:hover > span {
  width: 190px;
  height: 228px;

  position: absolute;
  visibility: visible;
  opacity: 1;
}

.column > div {
  visibility: visible;
  opacity: 1;
  position: absolute;
  border: solid;
  width: 1000px;
}

@media (max-width: 576px) {
  .parent {
    padding: 0;
  }
}
