.about {
  display: flex;
  align-items: center;
  min-height: 511px;
  padding: 100px 60px;
}

.container {
  display: flex;
}

.container * img {
  width: 100%;
  height: auto;
  margin-right: 56px;

  filter: drop-shadow(6px 6px 6px rgba(0, 0, 0, 0.25));
  border-radius: 20px;
}

.right {
  background-color: rgb(63, 156, 213, 0.9);
  padding: 3px;
  border-radius: 15px;
}

.right > h2 {
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;

  letter-spacing: 0.01em;
  color: #fff;
}

.right > p {
  font-weight: 500;
  font-size: 24px;

  letter-spacing: 0.01em;

  color: #ffffff;
}

.right > p > span {
  color: #000;
  cursor: pointer;
}

.about-paragraph > a {
  color: #4b97d4 !important;
}

@media (max-width: 576px) {
  .about {
    padding: 100px 10px;
  }
}

@media (max-width: 991px) {
  .left {
    margin-bottom: 12px !important;
  }

  .right {
    padding: 20px 0;
    border: solid 3px #3975a7;
  }

  .right > h2 {
    margin-bottom: -30px;
  }

  .right > p,
  .right > h2 {
    transform: scale(0.8);
  }
}
