.home {
  min-height: 1026px;
  padding: 60px;
}

.container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left > div {
  align-items: center;
  font-weight: 600;
  font-size: 110px;

  margin: 0;

  letter-spacing: 0.01em;

  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);
}

.left > div:nth-child(2),
.left > p {
  margin-left: 72px;
}

.left > p {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  letter-spacing: 0.01em;
  margin-top: 30px;

  color: #000000;
}

.left * button {
  width: 100%;
  height: 80px;
  background: #ffffff;
  border: 5px solid #3f9cd5;
  border-radius: 15px;

  color: #3f9cd7;

  font-weight: 600;
  font-size: 26px;

  letter-spacing: 0.01em;
  text-decoration: none;
}

.right > img {
  width: 100%;
  height: auto;
}

@media (max-width: 576px) {
  .home {
    padding: 50px 10px;
  }
}

@media (max-width: 1224px) {
  .left > p {
    text-align: center;
    margin: 0;
  }
}
