.team {
  background: url("/public/assets/images/ourteam/ourteam.png") no-repeat;
  background-size: cover;
  background-color: #3f9cd5;
  min-height: 486px;
  padding: 100px 0;
}

.container {
  width: 100%;
  height: 486px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-weight: 600;
  color: #fff;
  text-align: center;
  margin-bottom: -30px;
}

.expand {
  width: 38px;
  height: 38px;
  cursor: pointer;
  padding: 15px;
  background: #fff;
  filter: drop-shadow(3px 3px 4px rgba(0, 0, 0, 0.25));
  transition: 0.3s;
}

.expand:hover {
  transform: scale(1.1);
}

.images {
  margin: 0 20px;
  background: #d9d9d9;
  border-radius: 20px;
}

.hover-container {
  position: absolute;
  opacity: 1;
  width: 310px;
  height: 348px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.hover-container > div {
  padding: 10px;
  border-radius: 0 0 20px 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;
  height: 25%;
  background-color: rgba(0, 0, 0, 0.5);
}

.hover-container > div > div:nth-child(1) {
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.03em;
}

.hover-container > div > div:nth-child(2) {
  font-size: 14px;
  color: #fff;
}

.hover-container > div > div:last-child {
  visibility: hidden;
  opacity: 0;
  font-size: 14px;
  color: #fff;
  margin-top: -20px;
  font-style: italic;
}

.hover-container > div,
.hover-container > div > div:nth-child(1),
.hover-container > div > div:nth-child(2),
.hover-container > div > div:nth-child(3) {
  transition: 0.3s;
  width: 100%;
}

.hover-container:hover > div {
  padding: 30px 10px;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  background-color: rgb(50, 50, 50);
}

.hover-container:hover > div > div:nth-child(1) {
  transform: scale(1.5);
  word-wrap: break-word;
  width: 72%;
  line-height: normal;
  margin-bottom: 20px;
  color: #3f9cd5;
}

.hover-container:hover > div > div:nth-child(2) {
  font-weight: 600;
  transform: scale(1.2);
  margin: 10px 0 0 0;
  width: 80%;
}

.hover-container:hover > div > div:last-child {
  visibility: visible;
  opacity: 1;
  margin-top: 10px;
}

.images > img {
  border-radius: 20px;
}

@media (max-width: 576px) {
  .container {
    padding: 100px 10px;
  }

  .expand {
    margin-top: -15px;

    margin-right: -50px;
    transform: scale(0.8);
  }
}
