.layanan {
  background-color: #3f9cd5;
  padding: 100px 0;
}

.layanan-header {
  text-align: center;

  font-weight: 700;
  font-size: 32px;
  line-height: 51px;
  letter-spacing: 0.01em;
  color: #fff;
}

.layanan-desc {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  letter-spacing: 0.01em;

  color: #000000;
  margin-bottom: 60px;
}

.layanan-col {
  background-color: #fff;
  margin: 16px 8px;
  text-align: center;
  padding: 40px 20px !important;
  width: 100%;

  transition: 0.3s;

  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.layanan-title {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 400;
  color: #4b97d5;
}

.layanan-p {
  font-size: 14px;
}

.layanan-col:hover {
  transform: scale(1.03);
}

@media (max-width: 576px) {
  .layanan {
    padding: 100px 10px;
  }
}
