.portfolio {
  padding: 100px 0;
  min-height: 100px;
}

.portfolio-column-2 {
  display: flex;
  justify-items: center;
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.portfolio-carousel-content {
  display: flex;
  justify-content: space-between;
}

.portfolio-title {
  background: #57acf8;

  border-radius: 20px;
  padding: 5px;
  color: #fff;
}

.client {
  display: flex;
  justify-content: center;
  min-height: 200px;
  padding: 0 20px;
}

.client-img > img {
  width: 100%;
  height: auto;
}

.client-img {
  display: flex;
  align-items: center;
  margin: 0 20px;
  min-width: 128px;
}
