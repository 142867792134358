.container {
  padding: 100px 60px;
}

.title {
  text-align: center;

  font-weight: 700;
  font-size: 24px;
  color: #4b97d5;
  margin-bottom: 25px;
}

.row {
  display: flex;
  text-align: center;
}

.col {
  border-radius: 20px;
  margin: 20px;
  padding: 50px;

  background: rgb(241, 241, 241);
}

.col > a {
  color: #4b97d5;
  font-size: 14px;

  text-decoration: none;
}

.col > a > span {
  color: rgb(100, 100, 100);
  font-size: 14px;

  text-decoration: none;
}

.col > a > div {
  margin: 20px 0 0 0;
  font-size: 16px;
  color: #000;
  font-weight: bold;
}

@media (max-width: 576px) {
  .container {
    padding: 100px 10px;
  }
}
