.footer {
  margin-top: 300px;
  min-height: 422px;
  background: #f5f5f5;
  display: flex;
  align-items: flex-end;
  padding-top: 50px;
}

.footer-p {
  font-size: 14px;
}

.footer-p > a {
  text-decoration: none;
  color: rgb(99, 99, 99);
}

.footer-credit {
  font-size: 12px;
  color: #b5b5b5;
}

.footer-col {
  margin: 10px;
  color: rgb(99, 99, 99);
}

.footer-img {
  padding-bottom: 25px;
}

.footer-col > a,
.footer-col > p {
  font-size: 16px;
  text-decoration: none;
  color: rgb(99, 99, 99);
}

.footer-col > div {
  font-weight: 600;
  font-size: 16px;
  color: #4b97d5;
}

.footer-body {
  display: flex;
}

@media (min-width: 425px) {
  .footer-img {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
