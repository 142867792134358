.modal-about {
  background-image: url("/public/assets/images/about/modal.png");
  background-size: cover;
  display: flex;
}

.modal-body-content {
  background: rgba(37, 97, 134, 0.92);
  border-radius: 25px;
  min-height: 100%;
  word-wrap: break-word;
  color: #fff;
  padding: 40px;
}

.modal-body-content > div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

@media (max-width: 576px) {
  .modal-body-content {
    margin: -20px;
  }
}
